import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { DeviceDetectorService } from 'ngx-device-detector';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from '../../ui/dialog/dialog';
import { DialogLogoProComponent } from '../../ui/dialog-logo-pro/dialog-logo-pro';
import { DialogLightComponent } from '../../ui/dialog-light/dialog-light';
import { DialogCancelPlanComponent } from '../../ui/dialog-cancel-plan/dialog-cancel-plan';
import { DropzoneComponent, DropzoneConfigInterface } from 'ngx-dropzone-wrapper';

import { AuthService } from '../../providers/auth/auth.service';
import { HelpersProvider } from '../../providers/helpers/helpers';
import { UserProvider } from '../../providers/user/user';
import { ApiProvider } from '../../providers/api/api';
import { I18nProvider } from '../../providers/i18n/i18n';

import { codes } from '../../providers/helpers/countriesJson_ptBR';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-professores',
  templateUrl: './professores.component.html',
  // styleUrls: ['./professores.component.scss']
})
export class ProfessoresComponent implements OnInit {

  PAGE: any;
  STRINGS: any;

  protected headers: any;
  section: string = 'account'
  codes = codes;
  ismobile: boolean = false;
  environment: string = environment.dev;
  customerLegend: string = 'Cliente';

  teachers: any = [];
  totalTeachers: number = 0;
  selectedTeacher: number = 0;
  user: any = {};
  selectedUser: any = {};
  userBirthdate: any;
  userActive: boolean = false;
  userForm = new FormGroup({
    id: new FormControl('', [Validators.required]),
    name: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")]),
    password: new FormControl('', [Validators.required]),
    password_confirmation: new FormControl('', [Validators.required]),
    gender: new FormControl(''),
    birthdate: new FormControl(''),
    cpf: new FormControl('', [Validators.required]),
    cnpj: new FormControl(''),
    cref: new FormControl('', [Validators.required]),
    crefito: new FormControl('', [Validators.required]),
    crn: new FormControl('', [Validators.required]),
    nutriname: new FormControl('', [Validators.required]),
    nobr: new FormControl(''),
    uf: new FormControl(''),
    city: new FormControl(''),
    ddi: new FormControl(''),
    whatsapp: new FormControl(''),
    instagram: new FormControl(''),
  });
  nutri_samename: boolean = false;
  userSuperTreinosPlanId: any = '0';
  activity = { 
    "is_educf" : false,
    "is_fisio": false,
    "is_nutri": false,
    "is_stdnt": false
  };

  cardForm = new FormGroup({
    supertreinos_plan_id: new FormControl('', [Validators.required]),
    name: new FormControl('', [Validators.required]),
    number: new FormControl('', [Validators.required]),
    cvv: new FormControl('', [Validators.required]),
    month_late: new FormControl('', [Validators.required]),
    year_late: new FormControl('', [Validators.required])
  });

  purchase: any = {
    supertreinos_plan_id: 0,
  };
  students: any = {
    total: 0,
    max: 0,
    inactives: 0,
  };
  patients: any = {
    total: 0,
    max: 0,
    inactives: 0,
  };
  programs: any = {
    total: 0,
    max: 0,
  };
  menus: any = {
    total: 0,
    max: 0,
  };
  files: any = {
    total: 0,
    max: 0,
  };

  formSpinner: boolean = false;
  btIsDisabled: boolean = false;
  loading: boolean = false;
  showFormCC: boolean = false;

  ufs: any;

  totalItens = { count: 0 };
  maxItens: number = 12;
  search: any = {
    name: <string> '',
    email: <string> '',
  };

  tabUser: string = '0';

  payment_list = [];

  cropCheckInterval: any;

  @ViewChild(DropzoneComponent, {static: false}) dzAvatar?: DropzoneComponent;
  public avatar: DropzoneConfigInterface;

  @ViewChild(DropzoneComponent, {static: false}) dzLogo?: DropzoneComponent;
  public logo: DropzoneConfigInterface;

  @ViewChild(DropzoneComponent, {static: false}) dzLogoPdf?: DropzoneComponent;
  public logopdf: DropzoneConfigInterface;

  loading_link: boolean = false;
  link: string = '';
  product: any;

  renew: string = '0';

  intervalCheckTeacherPlan: any;
  clearInterval: boolean = false;

  constructor(
    public auth: AuthService,
    public toastr: ToastrService,
    public userProv: UserProvider,
    public helpers: HelpersProvider,
    public dialog: MatDialog,
    public api: ApiProvider,
    public router: Router,
    public route: ActivatedRoute,
    private device: DeviceDetectorService,
    public i18n: I18nProvider,
  ) {
    this.STRINGS = this.i18n.translate();
    this.PAGE = this.i18n.translate('ProfessoresComponent');

    this.headers = {'Authorization': `Bearer ${localStorage.getItem('LoggedInUser')}`};

    this.avatar = {
      paramName: 'avatar',
      resizeMethod: 'crop',
      resizeHeight: 150,
      resizeWidth: 150,
      addRemoveLinks: true,
      dictRemoveFile: this.STRINGS.dz_remover_imagem,
      dictCancelUpload: this.STRINGS.dz_cancelar_envio,
      dictCancelUploadConfirmation: this.STRINGS.dz_confirma_cancelar_envio,
      dictInvalidFileType: this.STRINGS.dz_arquivo_invalido,
      maxFiles: 1,
      transformFile: (file, done) => {
        this.cropCheckInterval = setInterval(() => { if (this.helpers.getLocallyStored('cropOngoing') === false) this.resetDropzoneUploads() }, 250);
        this.helpers.cropImage(file, done, 'dz-avatar', 300, 300);
      }
    };    

    this.logo = {
      paramName: 'logo',
      resizeMethod: 'crop',
      resizeHeight: 55,
      resizeWidth: 160,
      addRemoveLinks: true,
      dictRemoveFile: this.STRINGS.dz_remover_imagem,
      dictCancelUpload: this.STRINGS.dz_cancelar_envio,
      dictCancelUploadConfirmation: this.STRINGS.dz_confirma_cancelar_envio,
      dictInvalidFileType: this.STRINGS.dz_arquivo_invalido,
      maxFiles: 1,
      transformFile: (file, done) => {
        this.cropCheckInterval = setInterval(() => { if (this.helpers.getLocallyStored('cropOngoing') === false) this.resetDropzoneUploads() }, 250);
        this.helpers.cropImage(file, done, 'dz-logo', 500, 185);
      }
    };    

    this.logopdf = {
      paramName: 'logopdf',
      resizeMethod: 'crop',
      resizeHeight: 55,
      resizeWidth: 160,
      addRemoveLinks: true,
      dictRemoveFile: this.STRINGS.dz_remover_imagem,
      dictCancelUpload: this.STRINGS.dz_cancelar_envio,
      dictCancelUploadConfirmation: this.STRINGS.dz_confirma_cancelar_envio,
      dictInvalidFileType: this.STRINGS.dz_arquivo_invalido,
      maxFiles: 1,
      transformFile: (file, done) => {
        this.cropCheckInterval = setInterval(() => { if (this.helpers.getLocallyStored('cropOngoing') === false) this.resetDropzoneUploads() }, 250);
        this.helpers.cropImage(file, done, 'dz-logo-pdf', 500, 185);
      }
    };    
  }

  ngOnInit() {
    this.ismobile = this.device.isMobile();
    this.codes = this.helpers.sortByProperty(codes, 'nome');
    this.ufs = this.helpers.returnUFs();
    this.user = this.auth.retrieveLocalUser();
    this.avatar.dictDefaultMessage = ( (this.ismobile) ? this.PAGE.clique_envie_foto : this.PAGE.arraste_foto );
    this.logo.dictDefaultMessage = ( (this.ismobile) ? this.PAGE.clique_envie_logo : this.PAGE.arraste_logo );
    this.logopdf.dictDefaultMessage = ( (this.ismobile) ? this.PAGE.clique_envie_logo : this.PAGE.arraste_logo );
    this.activity = { 
      "is_educf" : this.user.is_educf,
      "is_fisio": this.user.is_fisio,
      "is_nutri": this.user.is_nutri,
      "is_stdnt": this.user.is_stdnt
    };    

    this.selectedUser = this.user;
    if (this.user.subs) {
      this.userSuperTreinosPlanId = this.user.subs.supertreinos_plan_id;
    }
    this.loadForm(this.selectedUser);
    this.getSubscriptions();
    this.updateDropzoneParams(this.avatar);
    this.updateDropzoneParams(this.logo);
    this.updateDropzoneParams(this.logopdf);

    this.route.queryParams.subscribe(
      (params: any) => {
        // Defaults to 0 if no query param provided.
        this.tabUser = params['tab'] || '0';
        this.renew = params['renew'] || '0';

        if (this.renew === '1') {
          this.showFormCC = true;
          this.cardForm.patchValue({supertreinos_plan_id: this.userSuperTreinosPlanId});
        }
      }
    );

    this.customerLegend = this.STRINGS.DashboardPage.clientes;
    if (this.user.account_type === 'teacher') {
      this.customerLegend = this.STRINGS.aluno;
    }
    if (this.user.account_type === 'nutri') {
      this.customerLegend = this.STRINGS.paciente;
    }

  }


  getTeacherList() {
    this.loading = true;
    this.userProv.getTeacherList().then(
      (res: any) => {
        this.loading = false;
        this.teachers = res;
        this.totalTeachers = 0;
        this.teachers.map((_prof: any) => {
          this.totalTeachers++;
        });
      },
      (err: any) => {
        this.loading = false;
        console.log(err);
      }
    );
  }

  doInfinite(){
    setTimeout(() => {
      this.maxItens = this.maxItens + 12;
    }, 250);
  }
  resetScroll() {
    this.maxItens = 12;
  }

  save() {
    document.getElementById('teacher-form-card').scrollTop = 0;

    // console.log(this.userForm.value);
    // this.loading = true;
    // return false;
    // verifique se a senha esta sendo enviada vazia
    // this.validateAllFormFields(this.userForm);

    if (!this.userForm.value.nobr) {
      if (this.userForm.value.cpf === '' || this.userForm.value.cpf === null) {
        this.toastr.error(this.PAGE.cpf_empty, '', this.helpers.toastrCfgs);
        return false;
      }

      let cpfValid = this.helpers.TestaCPF(this.userForm.value.cpf);
      if (!cpfValid) {
        this.toastr.error(this.PAGE.cpf_not_valid, '', this.helpers.toastrCfgs);
        return false;
      }
  
      if ((this.userForm.value.cref === '' || this.userForm.value.cref === null) && this.activity.is_educf) {
        this.toastr.error(this.PAGE.cref_not_empty, '', this.helpers.toastrCfgs);
        return false;
      }
  
      if ((this.userForm.value.crefito === '' || this.userForm.value.crefito === null) && this.activity.is_fisio) {
        this.toastr.error(this.PAGE.crefito_not_empty, '', this.helpers.toastrCfgs);
        return false;
      }
  
      if ((this.userForm.value.crn === '' || this.userForm.value.crn === null) && this.activity.is_nutri) {
        this.toastr.error(this.PAGE.crn_not_empty, '', this.helpers.toastrCfgs);
        return false;
      }

      if ((this.userForm.value.nutriname === '' || this.userForm.value.nutriname === null) && this.activity.is_nutri) {
        this.toastr.error(this.PAGE.nutriname_not_empty, '', this.helpers.toastrCfgs);
        return false;
      }
    }

    if (this.userForm.value.password !== this.userForm.value.password_confirmation) {
      this.toastr.error(this.STRINGS.RegistrationPage.senha_diferente, '', this.helpers.toastrCfgs);
      return false;
    }

    if (this.activity.is_educf === false && this.activity.is_fisio === false && this.activity.is_nutri === false && this.activity.is_stdnt === false) {
      this.toastr.error(this.STRINGS.RegistrationPage.escolha_prof, '', this.helpers.toastrCfgs);
      return false;
    }

    // formata a data de nascimento
    const dataString = JSON.stringify(this.userForm.value);
    const data = JSON.parse(dataString);
    if (data.birthdate) {
      data.birthdate = data.birthdate.substring(-10, 10);
    }
    if (data.password === '') {
      delete data.password;
      delete data.password_confirmation;
    }

    data.user_id = this.selectedUser.id;

    data.is_educf = (this.activity.is_educf) ? 1 : 0;
    data.is_fisio = (this.activity.is_fisio) ? 1 : 0;
    data.is_nutri = (this.activity.is_nutri) ? 1 : 0;
    data.is_stdnt = (this.activity.is_stdnt) ? 1 : 0;


    this.loading = true;
    this.userProv.updateUser(data).then(
      (_user: any) => {
        // console.log(user);
        this.loading = false;
        this.toastr.success(this.PAGE.dados_atualizados_sucesso, '', this.helpers.toastrCfgs);
      },
      (err: any) => {
        this.loading = false;
        console.log(err)
        this.toastr.error(this.STRINGS.algo_errado, '', this.helpers.toastrCfgs);
      }
    );

  }

  userActivation() {
    this.userProv.activateUser().then(
      (_user: any) => {
        // console.log(user);
        this.loading = false;
        this.toastr.success(this.PAGE.conta_ativada_sucesso, '', this.helpers.toastrCfgs);
      },
      (err: any) => {
        this.loading = false;
        console.log(err);
        this.toastr.error(this.STRINGS.algo_errado, '', this.helpers.toastrCfgs);
      }
    );    
  }


  callPlan(planId: number = 0) {
    if (planId === 0) {
      if (parseInt(this.purchase.supertreinos_plan_id) !== 0) {
        const title = this.PAGE.cancelamento_plano;
        const msg = false;
        let inner_msg = this.PAGE.cancelamento_plano_teacher;

        if (this.user.account_type === 'nutri') {
          inner_msg = this.PAGE.cancelamento_plano_nutri;
        }

        const dialogRef = this.dialog.open(DialogCancelPlanComponent, {
          id: 'dialog-plan-change',
          width: '360px',
          data: {
            title: title,
            message: msg,
            inner_msg: inner_msg,
            confirmation: 'Sim',
            bt_ok: this.PAGE.cancel_plano_bt_ok,
            bt_cancel: this.PAGE.cancel_plano_bt_cancel
          }
        });

        dialogRef.afterClosed().subscribe(result => {
          if (result === 'Sim') {
            this.cancelSubs();
          }
        });

      }

    } else if (parseInt(this.purchase.supertreinos_plan_id) < planId) {
      if (this.userActive === false) {
        const msg = this.PAGE.ativar_conta;
        const dialogRef = this.dialog.open(DialogComponent, {
          width: '360px',
          data: {title: this.PAGE.ativar_conta_title, inner_msg: msg, bt_ok: this.STRINGS.sim, confirmation: 'Sim'}
        });
  
        dialogRef.afterClosed().subscribe(result => {
          if (result === 'Sim') {
            this.userActivation();
            this.showFormCC = true;
            this.cardForm.patchValue({supertreinos_plan_id: planId});
          }
        });        

      } else {
        this.showFormCC = true;
        this.cardForm.patchValue({supertreinos_plan_id: planId});  
      }


    } else if (planId !== 0 && parseInt(this.purchase.supertreinos_plan_id) === planId) {

      const msg = '';
      const inner_msg = this.PAGE.plano_atual;

      const dialogRef = this.dialog.open(DialogCancelPlanComponent, {
        width: '360px',
        data: {
          title: '',
          message: msg,
          inner_msg: inner_msg,
          confirmation: this.STRINGS.sim,
          bt_ok: this.PAGE.plano_atual_bt_ok, 
          bt_cancel: this.PAGE.plano_atual_bt_cancel,
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result === 'Sim') {
          this.cancelSubs();
        }
      });

    } else {

      const msg = this.PAGE.plano_superior;
      const dialogRef = this.dialog.open(DialogComponent, {
        width: '360px',
        data: {title: this.PAGE.plano_superior_title, message: msg, confirmation: 'Sim'}
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result === 'Sim') {
          this.showFormCC = true;
          this.cardForm.patchValue({supertreinos_plan_id: planId});
        }
      });

    }
  }

  cancelSubs() {
    this.loading = true;
    this.userProv.cancelSubscription().then(
      (user: any) => {
        this.purchase = user.purchase;
        this.userSuperTreinosPlanId = this.purchase.supertreinos_plan_id;
        this.setUserPlanId(this.purchase);
        this.setSubscription();

        if (this.user.account_type === 'teacher') {
          this.files = user.files;
          this.programs = user.programs;
          this.students = user.students;
          this.menus = user.menus;
          (document.getElementById('nav-teacher-super-coach-logo') as HTMLFormElement).src = 'assets/imgs/logo-topo-stcoach.png';
        }

        if (this.user.account_type === 'nutri') {
          this.patients = user.students;
          this.menus = user.menus;
          (document.getElementById('nav-nutri-mydietpro-logo') as HTMLFormElement).src = 'assets/imgs/mydietpro/logo-topo-mydietpro.png';
        }

        this.loading = false;
        this.toastr.success(this.PAGE.dados_atualizados_sucesso, '', this.helpers.toastrCfgs);
      },
      (err: any) => {
        this.loading = false;
        console.log(err);
        this.toastr.error(this.STRINGS.algo_errado, '', this.helpers.toastrCfgs);
      }
    );
  }

  pay() {
    this.validateAllFormFields(this.cardForm);

    if (this.cardForm.value.supertreinos_plan_id === '' || this.cardForm.value.supertreinos_plan_id === null) {
      this.toastr.error(this.PAGE.plano_nao_selecionado, '', this.helpers.toastrCfgs);
      return false;
    }
    // if (this.cardForm.value.name === '' || this.cardForm.value.name === null) {
    //   this.toastr.error('O nome do cartão de crédito está em branco!');
    //   return false;
    // }
    if (this.cardForm.value.number === '' || this.cardForm.value.number === null) {
      this.toastr.error(this.PAGE.cc_branco, '', this.helpers.toastrCfgs);
      return false;
    }
    if (this.cardForm.value.cvv === '' || this.cardForm.value.cvv === null) {
      this.toastr.error(this.PAGE.cvc_branco, '', this.helpers.toastrCfgs);
      return false;
    }
    if (this.cardForm.value.month_late === '' || this.cardForm.value.month_late === null) {
      this.toastr.error(this.PAGE.expira_mes_branco, '', this.helpers.toastrCfgs);
      return false;
    }
    if (this.cardForm.value.year_late === '' || this.cardForm.value.year_late === null) {
      this.toastr.error(this.PAGE.expira_ano_branco, '', this.helpers.toastrCfgs);
      return false;
    }

    const dataString = JSON.stringify(this.cardForm.value);
    const data = JSON.parse(dataString);

    this.loading = true;
    // this.auth.retrieveLocalUser();
    this.userProv.subscribe(data).then(
      (user: any) => {
        this.purchase = user.purchase;       
        this.loading = false;
        this.showFormCC = false;
        this.userSuperTreinosPlanId = this.purchase.supertreinos_plan_id;
        this.setUserPlanId(this.purchase);
        this.setSubscription();
        // console.log(this.userSuperTreinosPlanId);
        if (this.user.account_type === 'teacher') {
          this.students = user.students;
          this.programs = user.programs;
          this.files = user.files;
          this.menus = user.menus;

          if (this.checkLogoPermission()) {
            if (this.selectedUser.logo === null || this.selectedUser.logo.indexOf('assets/imgs/logo-topo-stcoach.png') !== -1) this.selectedUser.logo = 'assets/imgs/logo-topo-stcoach.png';
            (document.getElementById('nav-teacher-super-coach-logo') as HTMLFormElement).src = (this.selectedUser.logo);
          } else {
            (document.getElementById('nav-teacher-super-coach-logo') as HTMLFormElement).src = 'assets/imgs/logo-topo-stcoach.png';
          }  
        }

        if (this.user.account_type === 'nutri') {
          this.patients = user.students;
          this.menus = user.menus;
  
          if (this.checkLogoPermission()) {
            if (this.selectedUser.logo === null || this.selectedUser.logo.indexOf('assets/imgs/mydietpro/logo-topo-mydietpro.png') !== -1) this.selectedUser.logo = 'assets/imgs/mydietpro/logo-topo-mydietpro.png';
            (document.getElementById('nav-nutri-mydietpro-logo') as HTMLFormElement).src = (this.selectedUser.logo);
          } else {
            (document.getElementById('nav-nutri-mydietpro-logo') as HTMLFormElement).src = 'assets/imgs/mydietpro/logo-topo-mydietpro.png';
          }  
        }

        this.toastr.success(this.PAGE.dados_atualizados_sucesso, '', this.helpers.toastrCfgs);
      },
      (err: any) => {
        this.loading = false;
        console.log(err);
        this.toastr.warning(this.PAGE.erro_reload, '', this.helpers.toastrCfgs);
        setTimeout(() => {
          location.reload();
        }, 3000);
        // console.log(err); this.toastr.error(this.STRINGS.algo_errado, '', this.helpers.toastrCfgs);
      }
    );

  }

  payPix() {
    this.gerarLinkPagamento();
  }

  gerarLinkPagamento() {
    // if (this.user.status === '1' || this.user.status === 1) {
    //   this.toastr.error('Cancele sua assinatura ou aguarde a expiração atual antes de assinar um plano pré-pago!');
    //   return false;
    // }
    // console.log(this.cardForm.value.supertreinos_plan_id)
    this.loading_link = true;
    this.userProv.postPayments({plan_id: this.cardForm.value.supertreinos_plan_id}).then(
      (res: any) => {
        // console.log(res);
        this.loading_link = false;
        this.link = res.purchase.url;
        this.product = res.purchase;
      },
      (err: any) => {
        this.loading_link = false;
        console.log(err);
      }
    );
  }

  getSubscriptions() {
    this.loading = true;
    this.userProv.subscriptions().then(
      (p: any) => {
        this.purchase = p.purchase;
        this.loading = false;
        this.userSuperTreinosPlanId = this.purchase.supertreinos_plan_id;
        this.setUserPlanId(this.purchase);
        if (this.user.account_type === 'teacher') {
          this.students = p.students;
          this.programs = p.programs;
          this.menus = p.menus;
          this.files = p.files;

          if (this.checkLogoPermission()) {
            if (this.selectedUser.logo === null || this.selectedUser.logo.indexOf('assets/imgs/logo-topo-stcoach.png') !== -1) this.selectedUser.logo = 'assets/imgs/logo-topo-stcoach.png';
            (document.getElementById('nav-teacher-super-coach-logo') as HTMLFormElement).src = (this.selectedUser.logo);
          }  
        }

        if (this.user.account_type === 'nutri') {
          this.patients = p.students;
          this.menus = p.menus;

          if (this.checkLogoPermission()) {
            if (this.selectedUser.logo === null || this.selectedUser.logo.indexOf('assets/imgs/mydietpro/logo-topo-mydietpro.png') !== -1) this.selectedUser.logo = 'assets/imgs/mydietpro/logo-topo-mydietpro.png';
            (document.getElementById('nav-nutri-mydietpro-logo') as HTMLFormElement).src = (this.selectedUser.logo);
          }  
        }
        this.listPayments();
        this.userActive = (p.teacher.status === 1) ? true : false;

        // placeholder to test subscriptions
        // if (this.user.account_type === 'nutri') this.userSuperTreinosPlanId = 3;
      },
      (err: any) => {
        this.loading = false;
        console.log(err); this.toastr.error(this.STRINGS.algo_errado, '', this.helpers.toastrCfgs);
      }
    );
  }

  listPayments() {
    this.loading = true;
    this.userProv.getPayments(3).then(
      (p: any) => {
        this.payment_list = p.payment_links;
        this.loading = false;
      },
      (err: any) => {
        this.loading = false;
        console.log(err); this.toastr.error(this.STRINGS.algo_errado, '', this.helpers.toastrCfgs);
      }
    );
  }

  abrirLinkpagamento(payment: any = null) {
    this.checkTeacherPlan();
    if (payment !== null) {
      if (payment.pagarme_payment_link_status === 'active') {
        window.open(payment.pagarme_payment_link_url, '_blank');
        this.link = null;
      }
    } else {
      window.open(this.link, '_blank');
      this.link = null;
    }
  }

  checkTeacherPlan() {
    const seconds: number = 1000;
    const minutes: number = 60 * seconds;
    const intervalo: number = (this.environment === 'prod') ? (15 * minutes) : (15 * seconds);

    this.intervalCheckTeacherPlan = setInterval(() => {
      // this.toastr.info('verificando status da conta...', '', this.helpers.toastrCfgs);
      console.log('verificando status da conta...');
      this.userProv.getPayments().then(
        (res: any) => {
          this.clearInterval = true;
          res.payment_links.map((item: any) => {
            // console.log(item);
            if (item.pagarme_payment_link_status === 'active'
                || item.pagarme_payment_link_status === 'created'
                || item.pagarme_payment_link_status === 'expired') {
              this.clearInterval = false;
            }
          });
          if (this.clearInterval) {
            clearInterval(this.intervalCheckTeacherPlan);
            this.userProv.subscriptions().then(
              (p: any) => {
                this.toastr.success(this.PAGE.verifica_pagamento, '', this.helpers.toastrCfgs);
                console.log('o plano foi atualizado');
                let user = this.auth.retrieveLocalUser();
                user.subs = p.purchase;
                this.auth.storeLocallyUser(user);
              },
              (err: any) => {
                console.log(err); this.toastr.error(this.STRINGS.algo_errado, '', this.helpers.toastrCfgs);
              }
            );
          };

          // this.payment_list = p.payment_links;
        },
        (err: any) => {
          console.log(err); this.toastr.error(this.STRINGS.algo_errado, '', this.helpers.toastrCfgs);
        }
      );

    }, intervalo)
  }

  checkLogoPermission() {
    this.user = this.auth.retrieveLocalUser();
    if (this.user.subs.supertreinos_plan_id === '3' || this.user.subs.supertreinos_plan_id === 3) return true;
    if (this.user.subs.supertreinos_plan_id === '4' || this.user.subs.supertreinos_plan_id === 4) return true;
    if (this.user.subs.supertreinos_plan_id === '12' || this.user.subs.supertreinos_plan_id === 12) return true;
    return false;
  }

  loadForm(user: any) {
    // console.log(user);
    // console.log(this.userForm.value);
    this.userForm.patchValue(user);
    this.userForm.value.goal_id = user.goal_id;
    if (!user.nobr) {
      if (user.cpf === '' || user.cpf === null) this.userForm.controls.cpf.markAsTouched();
    }

    this.userBirthdate = new Date(user.birthdate);
    const offsetMs = this.userBirthdate.getTimezoneOffset() * 3 * 60 * 1000;
    this.userBirthdate = new Date(this.userBirthdate.getTime() + offsetMs);

    this.getNutriName();

    if (this.userForm.value.instagram === null || this.userForm.value.instagram === '') {
      this.userForm.patchValue({'instagram':'https://www.instagram.com/'});
    }
  }

  getUserFormValidationMessage(el: any) {
    if (el.errors.required) {
      return this.STRINGS.campo_obrigatorio;
    }

    if (el.errors.incorrect || el.errors.pattern) {
      return this.STRINGS.campo_invalido;
    }
  }

  validateAllFormFields(formGroup: FormGroup) {         //{1}
    Object.keys(formGroup.controls).forEach(field => {  //{2}
      const control = formGroup.get(field);             //{3}
      if (control instanceof FormControl) {             //{4}
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {        //{5}
        this.validateAllFormFields(control);            //{6}
      }
    });
  }

  limparCampos() {
    const plano = this.cardForm.value.supertreinos_plan_id;
    this.cardForm.reset();
    this.cardForm.patchValue({supertreinos_plan_id: plano});
  }

  cardMoc() {
    const data: any = {
      name: 'Aardvark Silva',
      number: '4018720572598048',
      cvv: '123',
      month_late: '11',
      year_late: '30'
    }
    this.cardForm.patchValue(data);
  }

  toggleActiveTeacher(idx: any) {
    let msg: string;
    // console.log(this.teachers[idx].status);

    if (this.teachers[idx].status === 0) {
      msg = this.PAGE.aluno_desativado;
    } else if (this.teachers[idx].status === 1){
      msg = this.PAGE.aluno_ativado;
    } else if (this.teachers[idx].status === 9){
      msg = this.PAGE.aluno_bloqueado;
    }

    this.userProv.updateUser(this.teachers[idx]).then(
      (_user: any) => {
        this.toastr.success(msg, '', this.helpers.toastrCfgs);
      },
      (err: any) => {
        this.loading = false;
        console.log(err); this.toastr.error(this.STRINGS.algo_errado, '', this.helpers.toastrCfgs);
      }
    );
  }

  select(teacher: any) {
    this.selectedTeacher = teacher.id;
    this.selectedUser = teacher;
    this.loadForm(this.selectedUser);
    // this.getSubscriptions();
    this.students = teacher.students;
    this.programs = teacher.programs;
    this.files = teacher.files;
    this.updateDropzoneParams(this.avatar);
    this.updateDropzoneParams(this.logo);
    this.updateDropzoneParams(this.logopdf);
  }

  delete(teacher: any) {
    return false;
    // const msg = 'Todos os Programas, Alunos e Histórico de Compras desta conta serão apagados. Deseja apagar este conta?';
    // const dialogRef = this.dialog.open(DialogComponent, {
    //   width: '360px',
    //   data: {title: 'Apagar Conta de Professor', message: msg, confirmation: 'Sim'}
    // });

    // dialogRef.afterClosed().subscribe(result => {
    //   if (result === 'Sim') {
    //     this.loading = true;
    //     this.userProv.deleteUser(teacher).then(
    //       (_res: any) => {
    //         if (this.user.account_type === 'master' || this.user.account_type === 'admin') {
    //           this.toastr.success('Conta removida com sucesso do sistema!', '', this.helpers.toastrCfgs);
    //           this.getTeacherList();
    //         } else {
    //           this.loading = false;
    //           this.toastr.success('Um email de confirmação foi enviado para seu email. Siga as instruções para apagar sua conta definitivamente.', '', this.helpers.toastrCfgs);
    //           // this.logout();
    //         }

    //       },
    //       (err: any) => {
    //         this.loading = false;
    //         console.log(err); this.toastr.error(this.STRINGS.algo_errado, '', this.helpers.toastrCfgs);
    //       }
    //     );
    //   }
    // });
  }

  callProModal() {
    if (this.user.account_type === 'nutri') return false;
    const dialogRef = this.dialog.open(DialogLogoProComponent, {
      data: { date_selected: null },
    });

    dialogRef.afterClosed().subscribe(result => { console.log(result); });
  }

  setUserPlanId(purchase: any = null) {
    let user = this.auth.retrieveLocalUser();
    this.userSuperTreinosPlanId = this.purchase.supertreinos_plan_id;
    user.subs = purchase;
    this.auth.storeLocallyUser(user);
  }

  setSubscription() {
    const teacher = this.user;
    const subs = {
      'files': this.files,
      'programs': this.programs,
      'purchase': this.purchase,
      'students': this.students,
      'menus': this.menus,
      'patients': this.students,
      'teacher': teacher
    }
    this.helpers.storeLocally('teacherAccount', subs);
    if (this.purchase.pagarme) if (this.purchase.pagarme.status) if (this.purchase.pagarme.status === 'pending_payment') {
      this.helpers.storeLocally('teacherShowAlertPayment', true, 0);
    }

  }

  logout() {
    this.helpers.clearDB();
    localStorage.clear();
    this.user = null;
    this.router.navigate(['login'], { skipLocationChange: true });
  }

  helpSubs() {
    let msg = this.PAGE.ajuda_assinatura

    const dialogRef = this.dialog.open(DialogLightComponent, { width: '70vw', data: { message: msg } });
    dialogRef.afterClosed().subscribe(_result => {});
  }


  // dropzone funcs
  onAddedFile(_args: any) {
    //
  }

  onSendingFile(_$event: any) {
    //
  }

  onRemovedFile(_$event: any) {
    //
  }

  onUploadError(_$event: any) {
    //
  }

  onAvatarUploadSuccess($event: any) {
    // console.log($event);
    this.loading = false;
    this.selectedUser.id = $event[1].user.id;
    this.selectedUser.avatar = $event[1].user.avatar;
    this.toastr.success(this.PAGE.avatar_enviado_sucesso, '', this.helpers.toastrCfgs);

    this.resetDropzoneUploads();
    this.avatar.url = this.api.API_URL + '/v2/user/update/';
    this.avatar.params = {
      '_method': 'post',
      'id': this.selectedUser.id,
      'avatar': this.selectedUser.avatar,
    };

    this.auth.storeLocallyUser($event[1].user);
    (document.getElementById('profile-avatar') as HTMLFormElement).src = this.selectedUser.avatar;
  }

  onLogoUploadSuccess($event: any) {
    // console.log($event);
    this.loading = false;
    this.selectedUser = this.auth.retrieveLocalUser();
    this.selectedUser.id = $event[1].user.id;
    this.selectedUser.logo = $event[1].user.logo;
    this.toastr.success(this.PAGE.logo_enviado_sucesso, '', this.helpers.toastrCfgs);

    this.resetDropzoneUploads();
    this.logo.url = this.api.API_URL + '/v2/user/update/';
    this.logo.params = {
      '_method': 'post',
      'id': this.selectedUser.id,
      'logo': this.selectedUser.logo,
    };

    this.auth.storeLocallyUser(this.selectedUser);
    if (this.selectedUser.account_type === 'teacher') (document.getElementById('nav-teacher-super-coach-logo') as HTMLFormElement).src = ($event[1].user.logo);
    if (this.selectedUser.account_type === 'nutri') (document.getElementById('nav-nutri-mydietpro-logo') as HTMLFormElement).src = ($event[1].user.logo);
  }

  onLogoPdfUploadSuccess($event: any) {
    // console.log($event);
    this.loading = false;
    this.selectedUser = this.auth.retrieveLocalUser();
    this.selectedUser.id = $event[1].user.id;
    this.selectedUser.logo_pdf = $event[1].user.logo_pdf;
    this.toastr.success(this.PAGE.logo_enviado_sucesso, '', this.helpers.toastrCfgs);

    this.resetDropzoneUploads();
    this.logopdf.url = this.api.API_URL + '/v2/user/update/';
    this.logopdf.params = {
      '_method': 'post',
      'id': this.selectedUser.id,
      'logopdf': this.selectedUser.logo_pdf,
    };

    this.auth.storeLocallyUser(this.selectedUser);
  }

  updateDropzoneParams(dz: any): void {
    dz.headers = this.headers;
    dz.params = {
      'id': this.selectedUser.id,
      'avatar': (this.selectedUser.avatar === '' ? 'fake_cover_path' : this.selectedUser.avatar),
      'logo': (this.selectedUser.logo === '' ? 'default' : this.selectedUser.logo),
      'logopdf': (this.selectedUser.logo_pdf === '' ? 'default' : this.selectedUser.logo_pdf),
    };
    dz.url = this.api.API_URL + '/v2/user/update/';
  }

  resetDropzoneUploads(): void {
    this.dzAvatar.directiveRef.reset(true);
    this.dzLogo.directiveRef.reset(true);
    this.dzLogoPdf.directiveRef.reset(true);
  }

  removeAvatar() {
    // console.log('remover avatar');

    const data = {
      'id': this.selectedUser.id,
      'avatar': null,
    }

    this.loading = true;
    this.userProv.updateUser(data).then(
      (_user: any) => {
        // console.log(user);
        this.selectedUser.avatar = _user.avatar;
        this.loading = false;
        this.toastr.success(this.PAGE.foto_removida_sucesso, '', this.helpers.toastrCfgs);
      },
      (err: any) => {
        this.loading = false;
        console.log(err); this.toastr.error(this.STRINGS.algo_errado, '', this.helpers.toastrCfgs);
      }
    );
  }

  manageSubs() {
    window.open(this.user.subs.pagarme.manage_url, '_blank');
  }

  goToPix() {
    window.open('https://www.stcoach.app/pix', '_blank');
  }

  setNutriName() {
    if (this.userForm.value.nutriname === '' || this.userForm.value.nutriname === null) {
      this.userForm.patchValue({nutriname: this.userForm.value.name});
    } else {
      this.userForm.patchValue({nutriname: ''});
    }
  }

  getNutriName() {
    if (this.userForm.value.nutriname === this.userForm.value.name) {
      this.nutri_samename = true;
    } else {
      this.nutri_samename = false;
    }
  }

  copyTransfer(url: string = '') {
    console.log(url);
    if (this.helpers.copyTextToClipboard(url)) {
      this.toastr.success(this.STRINGS.link_transferencia, '', this.helpers.toastrCfgs);
    } else {
      this.toastr.error(this.STRINGS.copia_negada, '', this.helpers.toastrCfgs);
    }
  }

  validateEmail() {
    console.log(this.user);

    this.loading = true;
    this.auth.requestValidEmail().then(
      (res: any) => {
        console.log(res);
        this.loading = false;
      },
      (err: any) => {
        console.log(err);
        this.loading = false;
      }
    );
  }

}
