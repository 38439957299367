import { Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { DropzoneComponent, DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
import { FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

import { MatDialog } from '@angular/material/dialog';

import { ToastrService } from 'ngx-toastr';

import { DialogComponent } from '../../../ui/dialog/dialog';
import { DialogInputCustomComponent } from '../../../ui/dialog-input-custom/dialog-input-custom';
import { DialogSelectBannerComponent } from '../../../ui/dialog-select-banner/dialog-select-banner';
import { DialogLightComponent } from '../../../ui/dialog-light/dialog-light';

import { ProgramProvider } from '../../../providers/program/program';
import { GoalProvider } from '../../../providers/goal/goal';
import { ProgramCategoryProvider } from '../../../providers/program/category';
import { ApiProvider } from '../../../providers/api/api';
import { HelpersProvider } from '../../../providers/helpers/helpers';
import { UserProvider } from '../../../providers/user/user';
import { CustomerProvider } from '../../../providers/customer/customer';
import { I18nProvider } from '../../../providers/i18n/i18n';

import { environment } from '../../../environments/environment';


@Component({
  selector: 'app-block-programs',
  templateUrl: 'programs.html',
})
export class ProgramsComponent implements OnInit, OnChanges {
  @Output() setProgramId = new EventEmitter();
  @Output() setFastBuild = new EventEmitter();
  @Input() ismobile: boolean = false;
  @Input() istablet: boolean = false;
  @Input() reset: boolean = false;
  @Input() isFastBuild: boolean = false;
  @ViewChild('dropzoneCover', {static: false}) dropzoneCover?: DropzoneComponent;
  // @ViewChild('dropzoneThumb', {static: false}) dropzoneThumb?: DropzoneComponent;

  STRINGS: any;
  PAGE: any;
  mini: boolean = false;

  protected headers: any;
  protected API = (environment.API_ENV_URLS).replace('/api', '/');

  user: any = {};
  noScroll: boolean = false;
  isGrid: boolean = false;

  program: any = {
    id: <number> 0,
    cover_path: '',
    name: <string> null,
    user_id: <number> 1,
    subtitle: <string> null,
    category: <number> null,
    goal: <number> null,
    gender: <string> null,
    location: <string> null,
    focus: <string> 'completo',
    difficulty_level: <string> null,
    video_url: <string> 'https://player.vimeo.com/video/',
    description: <string> null,
    weeks: <string> null,
    days_per_week: <string> null,
    minutes_per_day: <string> null,
    sort: <number> 0,
    pay: <string> null,
    published: <number> 0,
    premium: <number> 0,
  };

  totalItens2 = { count: 0 };
  maxItens2: number = 30;
  page2: number = 0;
  totalPages: number = 0;

  searchProgram: any = {
    name: <string> '',
    premium: <any> '',
    published: <any> '',
    workout_muscle_ids: <any> [],
    workout_equip_ids: <any> [],
    workout_method_ids: <any> [],
  };

  cover_url_forceupdate: boolean = false;

  programs: any = [];
  selected_program = 0;
  sanitized_video_url: any = '';

  goals: any = [];
  categories: any = [];
  difficulties: any = [];

  banners: any = [];

  loading: boolean;

  // form validations
  programName = new FormControl('', [Validators.required]);
  // programVideoUrl = new FormControl('', [Validators.required]);
  programVideoUrl = new FormControl();

  cropCheckInterval: any;

  public config: DropzoneConfigInterface;
  public video_thumb: DropzoneConfigInterface;
  public featured_banner: DropzoneConfigInterface;

  account: any = {
    purchase: {
      supertreinos_plan_id: '0',
    },
    students: {
      total: <number> 0,
      max: <number> 0,
      inactives: <number> 0,
    },
    programs: {
      total: <number> 0,
      max: <number> 0,
    }
  }

  students: any;
  searchStudent: any = {
    name: <string> '',
  };
  maxItens: number = 5;
  totalItens = { count: 0 };
  reload: boolean = false;
  
  detailsClass: string = 'minimize';

  constructor(
    public toastr: ToastrService,
    public programProvider: ProgramProvider,
    public categoryProvider: ProgramCategoryProvider,
    public goalProvider: GoalProvider,
    public userProv: UserProvider,
    public api: ApiProvider,
    public helpers: HelpersProvider,
    public dialog: MatDialog,
    private router: Router,
    private student: CustomerProvider,
    private i18n: I18nProvider,
  ) {
    this.STRINGS = this.i18n.translate();
    this.PAGE = this.i18n.translate('ProgramasPage');

    this.headers = {'Authorization': `Bearer ${localStorage.getItem('LoggedInUser')}`};
    this.user = JSON.parse(localStorage.getItem('user'));

    // inicializa os dropzones
    this.config = {
      paramName: 'cover_img',
      thumbnailMethod: 'contain',
      thumbnailHeight: null,
      thumbnailWidth: null,
      addRemoveLinks: true,
      dictRemoveFile: this.STRINGS.dz_remover_imagem,
      dictCancelUpload: this.STRINGS.dz_cancelar_envio,
      dictCancelUploadConfirmation: this.STRINGS.dz_confirma_cancelar_envio,
      dictInvalidFileType: this.STRINGS.dz_arquivo_invalido,
      dictDefaultMessage: '',
      maxFiles: 1,
      transformFile: (file, done) => {
        this.cropCheckInterval = setInterval(() => { if (this.helpers.getLocallyStored('cropOngoing') === false) this.resetDropzoneUploads() }, 250);
        this.helpers.cropImage(file, done, 'dropzone-program-cover', 468, 622);
      }
      // autoProcessQueue: false,
    };

    this.video_thumb = {
      paramName: 'video_thumb',
      thumbnailMethod: 'contain',
      thumbnailHeight: null,
      thumbnailWidth: null,
      addRemoveLinks: true,
      dictRemoveFile: this.STRINGS.dz_remover_imagem,
      dictCancelUpload: this.STRINGS.dz_cancelar_envio,
      dictCancelUploadConfirmation: this.STRINGS.dz_confirma_cancelar_envio,
      dictInvalidFileType: this.STRINGS.dz_arquivo_invalido,
      maxFiles: 1,
      transformFile: (file, done) => {
        this.cropCheckInterval = setInterval(() => { 
          if (this.helpers.getLocallyStored('cropOngoing') === false) {
            this.helpers.deleteLocallyStored('cropOngoing');
            this.resetDropzoneUploads(); 
            this.updateDropzoneParams(this.video_thumb);
            clearInterval(this.cropCheckInterval);
          }
        }, 250)
        this.helpers.cropImage(file, done, 'dropzone-thumb', 544, 261);
      }
      // autoProcessQueue: false,
    };

    this.featured_banner = {
      paramName: 'featured_banner',
      thumbnailMethod: 'contain',
      thumbnailHeight: null,
      thumbnailWidth: null,
      addRemoveLinks: true,
      dictRemoveFile: this.STRINGS.dz_remover_imagem,
      dictCancelUpload: this.STRINGS.dz_cancelar_envio,
      dictCancelUploadConfirmation: this.STRINGS.dz_confirma_cancelar_envio,
      dictInvalidFileType: this.STRINGS.dz_arquivo_invalido,
      maxFiles: 1,
      // autoProcessQueue: false,
    };

    this.updateDropzoneParams(this.config);
    this.updateDropzoneParams(this.video_thumb);
    this.updateDropzoneParams(this.featured_banner);

  }

  ngOnInit() {
    // console.log('OnInit');
    this.loading = false;
    this.user = JSON.parse(localStorage.getItem('user'));

    this.config.dictDefaultMessage = ((this.ismobile || this.istablet)
        // ? 'Clique aqui para enviar o banner do Programa <br/> (468px de largura por 622px de altura)'
        // : 'Arraste o banner do Programa até aqui (468px de largura por 622px de altura)'
        ? this.PAGE.click_enviar_banner
        : this.PAGE.arraste_banner
    );

    this.video_thumb.dictDefaultMessage = ((this.ismobile || this.istablet)
        ? this.PAGE.click_enviar_capavideo
        : this.PAGE.arraste_capavideo
    );

    this.featured_banner.dictDefaultMessage = ((this.ismobile || this.istablet)
        ? this.PAGE.click_enviar_featured
        : this.PAGE.arraste_featured
    );
    this.getPrograms();

    let grid = this.helpers.getLocallyStored('gridView');
    if (grid !== null) this.toogleGrid(grid);

    if (this.isFastBuild) {
      this.isFastBuild = false;
      this.fastBuild();
    }
  }

  ngOnChanges() {
    // console.log('OnChanges');
    // console.log(this.reset);
    
    if (this.reset === true) {
      setTimeout(() => {
        this.goBack(false);
      },175);
    } else {
      if (this.loading === false) {
        this.programs = [];
        this.getPrograms();
      }
    }
  }


  drop(event: CdkDragDrop<string[]>) {
    const idxAtual = ( event.currentIndex + (this.page2*this.maxItens2) );
    const idxAnter = ( event.previousIndex + (this.page2*this.maxItens2) );

    // gambiarra pra resetar a ordenacao
    this.searchProgram.name = ' '; setTimeout(() => { this.searchProgram.name = ''; }, 100);
    //
    
    moveItemInArray(this.programs, idxAnter, idxAtual);
    this.programsReorder(this.programs);
  }

  mouseScroll(scroll: boolean = true) {
    this.noScroll = !scroll;
  }

  toogleGrid(grid: boolean = false) {
    this.isGrid = grid;
    this.helpers.storeLocally('gridView', grid);
  }

  getRequiredMessage(el: any) {
    if (el.hasError('required')) {
      return this.STRINGS.campo_obrigatorio;
    }

    if (el.hasError('incorrect')) {
      return this.STRINGS.campo_invalido;
    }
  }

  goBack(reload: boolean = true) {
    this.loading = true;
    this.setProgramId.emit(0);
    this.selected_program = 0;
    if (reload) this.getPrograms();
  }

  openBannerSelect() {
    const dialogRef = this.dialog.open(DialogSelectBannerComponent, {
      data: { account_status: parseInt(this.account.purchase.supertreinos_plan_id) },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result !== null && result !== '') {
        console.log(`Dialog result (ok): ${result}`);
        this.cover_url_forceupdate = true;
        this.program.cover_path = result;
        this.program.cover_url = result;
      }
      console.log(`Dialog result (no banner): ${result}`);
    });
  }

  getPrograms() {
    if (this.user) {
      this.loading = true;

      this.programProvider.programs().then(
        (res: any) => {
          this.programs = res.programs;
          this.helpers.storeLocally('programs', this.programs);
          // this.checkCloningProcess()
          // console.log(this.programs);
          for (let i = 0; i < this.programs.length; i++) {
            this.programs[i].total_students = 0;
          }
          // console.log(this.programs);
          this.loading = false;
          if (this.user.account_type === 'teacher') {
            this.getSubscriptions();
          } else {
            this.account.programs.max = 999999;
          }
        },
        (err: any) => {
          console.log(err);
          this.loading = false;
        });
    }
  }

  selectProgram(id: number) {
    if (this.account.programs.total > this.account.programs.max) {
      this.goToSubscribe();
    } else {
      this.getProgram(id);
      this.setProgramId.emit(id);
    }
  }

  getProgram(id: number) {
    this.loading = true;

    this.programProvider.program(id).then(
      (res: any) => {
        this.program = res.program;
        this.selected_program = this.program.id;
        this.updateDropzoneParams(this.video_thumb);
        this.updateDropzoneParams(this.featured_banner);

        if ((this.program.premium === 0) || (this.program.premium === '0')) {
          this.program.premium = false;
        } else {
          this.program.premium = true;
        }

        if (this.program.frequencies.length > 0) {
          let arrFreq = [];
          this.program.frequencies.forEach( function (item: any, _index: any, _arr: any) {
            arrFreq.push(item.frequency.toString());
          });
          this.program.frequency = arrFreq;
        }

        if ((this.program.published === 0) || (this.program.published === '0')) {
          this.program.published = false;
        } else {
          this.program.published = true;
        }

        // if (this.program.cover_path === '1') {
        //   this.program.cover_url = '/img/PROGRAMA-BANNER-PADRAO.jpg';
        // }

        if ((this.program.featured_banner).indexOf('undefined') > -1) {
          this.program.featured_banner = '';
        }

        if (this.program.video_url === null || this.program.video_url === '' || this.program.video_url === 'https://player.vimeo.com/video/') {
          this.program.video_url = 'https://player.vimeo.com/video/';
          this.sanitized_video_url = '';
        }

        if (this.program.video_url !== 'https://player.vimeo.com/video/' && this.program.video_url !== null && this.program.video_url !== '') {
          this.sanitized_video_url = this.helpers.sanitizerVideoURL(this.program.video_url);
        }

        this.config.headers = this.headers;

        this.config.url = this.api.API_URL + '/v2/programs/' + id;
        this.config.params = {
          '_method': 'patch',
          'name': this.program.name,
          'video_url': this.program.video_url,
          'cover_path': this.program.cover_path,
        };
        if (this.program.goal > 0) {
          this.config.params.goal = this.program.goal;
        } else {
          this.config.params.goal = 2;
        }

        this.getGoals();
        this.getCategories();
        this.getDifficulty();
        // this.loading = false;
      },
      (err: any) => {
        console.log(err);
        this.loading = false;
      });
  }

  getGoals() {
    this.loading = true;

    this.goalProvider.index().then(
      (res: any) => {
        this.goals = res.goals;
        // console.log(this.goals);
        if (this.program.id === 0) {
          this.program.goal = this.goals[0].id;
        } else {
          this.program.goal = this.program.goals[0].id;
        }
        this.loading = false;
      },
      (err: any) => {
        console.log(err);
        this.loading = false;
      });
  }


  getCategories() {
    this.loading = true;
    this.categoryProvider.index().then(
      (res: any) => {
        this.categories = res.categories;
        if (this.program.id === 0) {
          this.program.category = this.categories[0].old_id;
        }
        this.loading = false;
      },
      (err: any) => {
        console.log(err);
        this.loading = false;
      });
  }

  getDifficulty() {
    this.difficulties = [
      {"id":"Iniciante",      "name": this.STRINGS.iniciante},
      {"id":"Intermediário",  "name": this.STRINGS.intermediario},
      {"id":"Avançado",       "name": this.STRINGS.avancado},
      {"id":"Expert",         "name": this.STRINGS.expert},
    ];
    if (this.program.id === 0) {
      this.program.difficulty_level = this.difficulties[0].id;
    }
  }
  returnDiff(id: string = null) {
    switch (id) {
      case 'Iniciante':
        return this.STRINGS.iniciante;
        break;

      case 'Intermediário':
        return this.STRINGS.intermediario;
        break;

      case 'Avançado':
        return this.STRINGS.avancado;
        break;

      case 'Expert':
        return this.STRINGS.expert;
        break;
    
      default:
        return this.STRINGS.iniciante;
        break;
    }
  }

  new() {
    if (this.user.account_type === 'teacher' && this.account.programs.total >= this.account.programs.max) {
      this.goToSubscribe();
    } else {
      this.loading = true;
      // timeout necessario para inicializar o dropzone
      setTimeout( () => {
        this.selected_program = -1;
        this.sanitized_video_url = '';
        this.program = this.helpers.resetObject(this.program);
        delete this.program.created_at;
        delete this.program.updated_at;
        delete this.program.goals;
        this.program.cover_path = true;
        this.program.cover_url = this.API + 'img/PROGRAMA-BANNER-PADRAO.jpg';
        this.program.video_url = 'https://player.vimeo.com/video/';
        this.program.video_url_thumb = this.API + 'img/AGENDA-BANNER-PADRAO.jpg';
        this.program.gender = 'ambos';
        this.program.focus = 'completo';
        this.program.location = 'qualquer';
        this.programName.reset();
        this.programVideoUrl.reset();
        this.programVideoUrl.setValue('https://player.vimeo.com/video/');

        this.getGoals();
        this.getCategories();
        this.getDifficulty();
        this.loading = false;
      }, 1000);
    }
  }

  saveProgram() {
    this.loading = true;
    let valid = true;
    let el: any;

    this.updateTranslation();

    if (this.program.name === '') {
      this.programName.markAsTouched();
      valid = false;
    }
    // if (this.program.video_url === '') {
    //   this.programVideoUrl.markAsTouched();
    //   valid = false;
    // }
    // if (this.program.video_url === 'https://player.vimeo.com/video/') {
    //   this.programVideoUrl.setErrors({'incorrect': true});
    //   valid = false;
    // }
    if (this.helpers.validateURL(this.program.video_url)) {
      this.programVideoUrl.setErrors({'incorrect': true});
      valid = false;
    }
    if (valid === false) {
      this.loading = false;
      el = document.getElementById('iframeVideo');
      el.scrollIntoView();
      return false;
    }

    this.program.pay = this.program.premium;
    if (this.program.clone === null || this.program.clone === '' || this.program.clone === undefined) {
      this.program.clone = 'original';
    }
    this.program.cover_path = (this.program.cover_path === '') ? delete this.program.cover_path : this.program.cover_path;
    if (this.program.id > 0) {
      this.updateProgram();
    }
    if (this.program.id === 0) {
      this.createProgram();
    }
    this.resetDropzoneUploads();
  }

  updateProgram() {
    const programUploaded = this.program;
    if (!this.cover_url_forceupdate) {
      delete programUploaded.cover_path;
    }
    delete programUploaded.cover_url;
    delete programUploaded.video_url_thumb;
    delete programUploaded.show_tag;
    programUploaded.goal = this.program.goal;

    this.programProvider.patchProgram(programUploaded).then(
      (res: any) => {
        this.program = res.program;
        this.toastr.success(this.PAGE.atualizado_sucesso, '', this.helpers.toastrCfgs);
        // this.loading = false;
        this.goBack();
      },
      (err: any) => {
        console.log(err);
        this.toastr.warning(err.error.err, '', this.helpers.toastrCfgs);
        // this.loading = false;
        // this.goBack();
      });
  }

  createProgram() {
    const programUploaded = this.program;
    delete programUploaded.video_url_thumb;
    delete programUploaded.show_tag;

    // console.log(this.program);
    // this.loading = false;
    // return false;

    this.programProvider.postProgram(this.program).then(
      (res: any) => {
        this.program = res.program;
        this.toastr.success(this.PAGE.cadastrado_sucesso, '', this.helpers.toastrCfgs);
        this.loading = false;
        this.goBack();
        // this.selectProgram(this.program.id);
      },
      (err: any) => {
        console.log(err);
        this.toastr.warning(err.error.err, '', this.helpers.toastrCfgs);
        this.loading = false;
      });
  }

  fastBuild() {
    this.loading = true;
    this.setBlankProgram();

    this.toastr.success((this.PAGE.iniciando_treino_rapido || 'Iniciando construção do Programa Rápido. Por favor, aguarde alguns instantes.'), '', this.helpers.toastrCfgs);

    this.programProvider.postProgram(this.program).then(
      (res: any) => {
        this.program = res.program;
        // this.toastr.success(this.PAGE.cadastrado_sucesso, '', this.helpers.toastrCfgs);
        this.loading = false;
        this.selectProgram(this.program.id);
        this.setProgramId.emit(this.program.id);
        this.setFastBuild.emit(this.program.id);
      },
      (err: any) => {
        console.log(err);
        this.toastr.warning(err.error.err, '', this.helpers.toastrCfgs);
        this.loading = false;
      });
  }

  setBlankProgram() {
    this.program.name = 'Programa 1';
    this.program.cover_path = true;
    this.program.user_id = 0;
    this.program.subtitle = "";
    this.program.category = 1;
    this.program.goal = 1;
    this.program.gender = "ambos";
    this.program.location = "qualquer";
    this.program.focus = "completo";
    this.program.difficulty_level = "Iniciante";
    this.program.description = "";
    this.program.weeks = "";
    this.program.days_per_week = "";
    this.program.minutes_per_day = "";
    this.program.pay = 0;
    this.program.cover_url = "https://www.api.homolog.supertreinosapp.com/img/PROGRAMA-BANNER-PADRAO.jpg";
    this.program.translations = "";
    this.program.clone = "original";    
  }
  
  goToSubscribe() {
    let msg = '';
    let bt_ok = '';
    let bt_cancel_hide = false;
    if (this.account.purchase.supertreinos_plan_id === "4") {
      msg = this.PAGE.limite_maximo;
      bt_ok = this.STRINGS.bt_ok;
      bt_cancel_hide = true;
    } else {
      msg = this.PAGE.limite_programas;
      bt_ok = this.PAGE.bt_upgrade;
      bt_cancel_hide = false;
    }

    const dialogRef = this.dialog.open(DialogComponent, {
      panelClass: 'subscribe-modal',
      width: '360px',
      data: {title: this.PAGE.cadastro_programas, message: msg, confirmation: 'Sim', bt_ok: bt_ok, bt_cancel_hide: bt_cancel_hide}
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'Sim' && this.account.purchase.supertreinos_plan_id !== "4") {
        this.router.navigate(['professor/perfil'], { skipLocationChange: true, queryParams: { tab: 1 } });
      }
    });
  }  

  cloneProgram(id = null) {
    if (this.account.programs.total >= this.account.programs.max) {
      this.goToSubscribe();
    } else {
      this.cloneProgramConfirmation(id, this.user.id);
    }
  }

  cloneProgramConfirmation(id: number, user_id: number): void {
    const msg = this.PAGE.clone_confirmation;
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '360px',
      panelClass: 'mat-dialog-clone',
      data: {title: this.PAGE.clone_confirmation_title, inner_msg: msg, confirmation: 'clonar'}
    });

    if (id === null) { id = this.program.id; }

    dialogRef.afterClosed().subscribe(result => {
      // console.log('The dialog was closed: ' + result);
      if (result === 'clonar') {
        this.setProgramId.emit(0);
        this.loading = true;
        this.programProvider.cloneProgram({id, user_id}).then(
          (_res: any) => {
            this.goBack();
            this.toastr.success(this.PAGE.clone_confirmation_success, '', this.helpers.toastrCfgs);
          },
          (err: any) => {
            console.log(err);
            this.toastr.warning(this.STRINGS.algo_errado, '', this.helpers.toastrCfgs);
            this.loading = false;
          });
      }
    });
  }

  // sendToTeacher(id: any = null) {
  //   const msg = 'Deseja realmente enviar este Programa para sua conta de Professor?';
  //   const dialogRef = this.dialog.open(DialogSendToTeacherComponent, {
  //     width: '420px',
  //     data: {title: 'Enviar Programa', message: msg, confirmation: 'enviar'}
  //   });
  //
  //   dialogRef.afterClosed().subscribe(teacher_id => {
  //     if (teacher_id > 0) {
  //       // console.log(teacher_id);
  //       // console.log(id);
  //       this.programProvider.sendProgram({teacher_id: teacher_id, program_id: id}).then(
  //         (_res: any) => {
  //           this.goBack();
  //           this.toastr.success('O Programa foi enviado com sucesso!');
  //         },
  //         (err: any) => {
  //           console.log(err);
  //           this.toastr.warning(err.error.err);
  //           this.loading = false;
  //         });
  //   } else {
  //     console.log('nenhum professor selecionado');
  //   }
  //   });
  // }

  deleteProgram(id = null) {
    this.deleteProgramConfirmation(id);
  }

  deleteProgramConfirmation(id: number): void {
    const msg = this.PAGE.apagar_programa;
    const dialogRef = this.dialog.open(DialogInputCustomComponent, {
      width: '360px',
      data: {title: this.PAGE.apagar_programa_title, placeholder: this.PAGE.apagar_programa_placeholder, message: msg, confirmation: 'Sim'}
    });

    if (id === null) { id = this.program.id; }

    dialogRef.afterClosed().subscribe(result => {
      // console.log('The dialog was closed: ' + result);
      if (result === this.PAGE.apagar_programa_confirmacao) {
        this.setProgramId.emit(0);
        this.loading = true;
        this.programProvider.deleteProgram(id).then(
          (_res: any) => {
            this.goBack();
            this.toastr.success(this.PAGE.apagar_programa_confirmacao_sucesso, '', this.helpers.toastrCfgs);
          },
          (err: any) => {
            console.log(err);
            this.toastr.warning(this.STRINGS.algo_errado, '', this.helpers.toastrCfgs);
            this.loading = false;
          });
      }
    });
  }

  deleteClone(id = null) {
    this.deleteCloneConfirmation(id);
  }
  deleteCloneConfirmation(id: number): void {
    const msg = this.PAGE.apagar_clone;
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '360px',
      data: {title: this.PAGE.apagar_clone_title, message: msg, confirmation: 'deletar'}
    });

    if (id === null) { id = this.program.id; }

    dialogRef.afterClosed().subscribe(result => {
      // console.log('The dialog was closed: ' + result);
      if (result === 'deletar') {
        this.setProgramId.emit(0);
        this.loading = true;
        this.programProvider.deleteProgram(id).then(
          (_res: any) => {
            this.goBack();
            this.toastr.success(this.PAGE.apagar_clone_sucesso, '', this.helpers.toastrCfgs);
          },
          (err: any) => {
            console.log(err);
            this.toastr.warning(this.STRINGS.algo_errado, '', this.helpers.toastrCfgs);
            this.loading = false;
          });
      }
    });
  }

  programOrderUp(program: any) {
    this.programOrder(program, 'up');
  }

  programOrderDown(program: any) {
    this.programOrder(program, 'down');
  }

  programOrder(program: any, order: any) {
    // delete program.cover_path;
    // delete program.cover_url;
    program._order = order;

    this.loading = true;
    this.programProvider.patchProgram(program).then(
      (_res: any) => {
        this.toastr.success(this.PAGE.reordenar_programa_sucesso, '', this.helpers.toastrCfgs);
        this.goBack();
      },
      (err: any) => {
        console.log(err);
        this.toastr.warning(this.STRINGS.algo_errado, '', this.helpers.toastrCfgs);
        this.loading = false;
        // this.goBack();
      });
  }

  programsReorder(programs: any) {
    let programs_list = new Array;
    Object.assign(programs_list, programs);
    this.programProvider.reorderPrograms(programs_list.reverse()).then(
      (_res: any) => {
        this.toastr.success(this.PAGE.reordenar_programas_sucesso, '', this.helpers.toastrCfgs);
      },
      (err: any) => {
        console.log(err);
        this.toastr.warning(this.STRINGS.algo_errado, '', this.helpers.toastrCfgs);
      });
  }


  helpVideo() {
    let msg = this.PAGE.help_video;

    const dialogRef = this.dialog.open(DialogLightComponent, { width: '360px', data: { message: msg } });
    dialogRef.afterClosed().subscribe(_result => {});
  }


  helpClone() {
    let msg = this.PAGE.help_clone;

    const dialogRef = this.dialog.open(DialogLightComponent, { width: '360px', data: { message: msg } });
    dialogRef.afterClosed().subscribe(_result => {});
  }



  // dropzone events
  changeProgramName() {
    this.resetDropzoneUploads();
    this.updateDropzoneParams(this.config);
  }

  changeProgramVideoUrl() {
    this.resetDropzoneUploads();
    this.updateDropzoneParams(this.config);
    this.sanitized_video_url = '';
    if (this.program.video_url !== '' && this.program.video_url !== null && this.program.video_url !== 'https://player.vimeo.com/video/') {
      this.sanitized_video_url = this.helpers.sanitizerVideoURL(this.program.video_url);
      this.program.video_url = this.program.video_url.replace('shorts/', 'watch?v=');
    }
    console.log(this.sanitized_video_url);
  }

  onAddedFile(_args: any) {
    let valid = true;
    if (this.program.name === '') {
      this.loading = false;
      this.resetDropzoneUploads();
      valid = false;
      this.programName.markAsTouched();
    }
    // if (this.program.video_url === '') {
    //   this.loading = false;
    //   this.resetDropzoneUploads();
    //   valid = false;
    //   this.programVideoUrl.markAsTouched();
    // }
    // if (this.program.cover_path === '') this.program.cover_path = 'fake_cover_path';
    if (valid === false) {
      return false;
    }
  }

  onSendingFile(_$event: any) {
    // this.program.cover_path = '';
    this.program.cover_url = false;
  }

  onRemovedFile(_$event: any) {
    //
  }

  onUploadError(_$event: any) {
    //
  }

  onUploadSuccess($event: any) {
    this.loading = false;
    // this.program = $event[1].program;
    this.program.id = $event[1].program.id;
    this.program.cover_url = $event[1].program.cover_url;
    this.program.cover_path = $event[1].program.cover_url;
    this.program.sort = $event[1].program.sort;
    this.setProgramId.emit(this.program.id);
    this.toastr.success(this.PAGE.banner_upload_sucesso, '', this.helpers.toastrCfgs);

    this.resetDropzoneUploads();
    this.config.url = this.api.API_URL + '/v2/programs/' + this.program.id;
    this.config.params = {
      '_method': 'patch',
      'name': this.program.name,
      'video_url': this.program.video_url,
      'cover_path': this.program.cover_path,
    };
    if (this.program.goal > 0) {
      this.config.params.goal = this.program.goal;
    } else {
      this.config.params.goal = 2;
    }
    if (this.program.program_id > 0) {
      this.config.params.program_id = this.program.program_id;
    }
  }

  onThumbUploadSuccess($event: any) {
    this.loading = false;
    // this.program = $event[1].program;
    this.program.id = $event[1].program.id;
    this.program.video_url_thumb = $event[1].program.video_url_thumb;
    this.program.sort = $event[1].program.sort;
    this.setProgramId.emit(this.program.id);
    this.toastr.success(this.PAGE.thumb_upload_sucesso, '', this.helpers.toastrCfgs);

    this.resetDropzoneUploads();
    this.video_thumb.url = this.api.API_URL + '/v2/programs/' + this.program.id;
    this.video_thumb.params = {
      '_method': 'patch',
      'name': this.program.name,
      'video_url': this.program.video_url,
      // 'cover_path': this.program.cover_path,
      'video_thumb': this.program.video_thumb,
    };
    if (this.program.goal > 0) {
      this.video_thumb.params.goal = this.program.goal;
    } else {
      this.video_thumb.params.goal = 2;
    }
    if (this.program.program_id > 0) {
      this.video_thumb.params.program_id = this.program.program_id;
    }
  }

  onFeaturedUploadSuccess($event: any) {
    this.loading = false;
    // this.program = $event[1].program;
    this.program.id = $event[1].program.id;
    this.program.featured_banner = $event[1].program.featured_banner;
    this.program.sort = $event[1].program.sort;
    this.setProgramId.emit(this.program.id);
    this.toastr.success(this.PAGE.thumb_upload_sucesso, '', this.helpers.toastrCfgs);

    this.resetDropzoneUploads();
    this.featured_banner.url = this.api.API_URL + '/v2/programs/' + this.program.id;
    this.featured_banner.params = {
      '_method': 'patch',
      'name': this.program.name,
      'video_url': this.program.video_url,
      // 'cover_path': this.program.cover_path,
      'featured_banner': this.program.featured_banner,
    };
    if (this.program.goal > 0) {
      this.featured_banner.params.goal = this.program.goal;
    } else {
      this.featured_banner.params.goal = 2;
    }
    if (this.program.program_id > 0) {
      this.featured_banner.params.program_id = this.program.program_id;
    }
  }

  resetDropzoneUploads(): void {
    this.helpers.deleteLocallyStored('cropOngoing');
    clearInterval(this.cropCheckInterval);

    this.dropzoneCover.directiveRef.reset(true);
    // this.dropzoneThumb.directiveRef.reset(true);
  }

  updateDropzoneParams(dz: any): void {
    dz.headers = this.headers;

    if (dz.paramName === 'cover_img') {
      dz.params = {
        'name': this.program.name,
        'cover_path': (this.program.cover_path === '' ? 'fake_cover_path' : this.program.cover_path),
      };
    }
    if (dz.paramName === 'video_thumb') {
      dz.params = {
        'name': this.program.name,
        'video_thumb': this.program.video_thumb,
      };
    }
    if (dz.paramName === 'featured_banner') {
      dz.params = {
        'name': this.program.name,
        'featured_banner': this.program.featured_banner,
      };
    }


    if (this.program.id > 0) {
      dz.url = this.api.API_URL + '/v2/programs/' + this.program.id;
      dz.params._method = 'PATCH';
    } else {
      dz.url = this.api.API_URL + '/v2/programs/';
    }

    if (this.program.goal > 0) {
      dz.params.goal = this.program.goal;
    } else {
      dz.params.goal = 2;
    }
  }

  getSubscriptions() {
    this.loading = true;
    this.userProv.subscriptions().then(
      (p: any) => {
        this.account = p;
        this.totalItens2.count = p.programs.total;
        this.totalPages = Math.ceil(this.totalItens2.count/this.maxItens2);
        // this.account.programs.max = 2;
        this.helpers.storeLocally('teacherAccount', p);
        this.loading = false;
        this.getCustomers();
      },
      (err: any) => {
        this.loading = false;
        this.toastr.error(this.STRINGS.algo_errado, '', this.helpers.toastrCfgs);
        // this.toastr.error(err || 'Algo não funcionou bem. Tente novamente.', '', this.helpers.toastrCfgs);
      }
    );
  }

  getCustomers(force: boolean = false) {
    this.loading = true;
    // this.student.getCustomers().then(
    //   (res: any) => {
    //     this.loading = false;
    //     // this.students = [];
    //     this.students = this.helpers.sortByProperty(res.customers, 'name');
    //   },
    //   (err: any) => {
    //     this.loading = false;
    //     console.log(err);
    //   }
    // );
    // return true;

    this.helpers.getCacheDB('cachedStudents').then(
      (cache: any) => {
        // console.log(cache);
        if (cache === undefined || cache === null || force) {

          this.student.getCustomers().then(
            (res: any) => {
              this.students = this.helpers.sortByProperty(res.customers, 'name');
              this.helpers.addCacheDB('cachedStudents', res.customers);
              this.loading = false;
            },
            (err: any) => {
              // this.toastr.error(this.PAGE.clientes_nao_retornados, '', this.helpers.toastrCfgs);
              this.loading = false;
            }
          );

        } else {
          this.students = null;
          const data = JSON.parse(cache.data);
          this.students = this.helpers.sortByProperty(data, 'name');;
          this.loading = false;
        }
      },
      (err: any) => {
        console.log(err);
      }
    );     
  }

  checkCloningProcess() {
    if (this.programs) {
      this.programs.map((program: any) => {
        if (program.clone === 'processing') {
          // console.log('programa processando clonagem: ID ' + program.id + '/ Date: ' + new Date);
          setTimeout(() => {
            this.programProvider.program(program.id).then(
              (res: any) => {
                // console.log(res);
                (res.program.clone === 'processing') ? this.checkCloningProcessAgain() : this.markProgramAsCloned(res.program.id);
              },
              (err: any) => {
                console.log(err);
              }
            );
          }, 60000);
        }
      });
    }
  }
  checkCloningProcessAgain() {
    this.checkCloningProcess();
  }
  markProgramAsCloned(id: number) {
    for(let i = 0; i < this.programs.length; i = i + 1 ) {
      if (this.programs[i].id === id) {
        // console.log('clonagem finalizada: ID ' + this.programs[i].id + '/ Date: ' + new Date);
        this.programs[i].clone = 'cloned';
      }
    }
    this.checkCloningProcess();
  }

  getGoalFromProgram(program: any) {
    if (program) {
      if (program.goals[0]) {
        if (program.goals[0].name) {
          // return program.goals[0].name;
          return this.helpers.returnTranslation(program.goals[0]);
        }
      }
    }
  }

  updateTranslation() {
    const translations = (document.getElementById('translationFieldPrograms') as HTMLInputElement).value;
    this.program.translations = translations;
  }

  processTranslation(value: any) {
    if (value) {
      (document.getElementById('translationFieldPrograms') as HTMLInputElement).value = JSON.stringify(value);
    }
  }

  getCloneTimer(date: any) {
    if (date === null) return date;
    const mins15 = (1000 * 60 * 16);
    const offset = new Date().getTimezoneOffset() * 60 * 1000;
    const programTime = new Date(date).getTime() + mins15;
    const currentTime = new Date().getTime() + offset;
    const timeElapsed = (programTime - currentTime);
    // const timeLeft = (timeElapsed > 0) ? this.millisToMinutesAndSeconds(timeElapsed) : 'Ocorreu um erro.';
    const timeLeft = (timeElapsed > 0) ? this.millisToMinutesAndSeconds(timeElapsed) : null;
    return timeLeft;
  }

  millisToMinutesAndSeconds(millis: any) {
    const minutes = Math.floor(millis / 60 / 1000);
    const seconds = (millis % 60000 / 1000).toFixed(0);
    // return minutes + ":" + (parseInt(seconds) < 10 ? '0' : '') + seconds;

    let msg = (minutes < 1 && parseInt(seconds) > 0) ? this.PAGE.menos_minuto : minutes + ' ' + this.PAGE.minutos;
    return msg;
  }

  toggleAllStudent(program: any, v: boolean = false) {
    setTimeout(() => {
      this.loading = true;
      // v, o valor do slider é invertido porque usamos o evento click nele pra executar a funcao,
      // logo pega o estado inicial dele, nao o final como acontece com o evento change
      this.programProvider.admCustomerToggleAllProgramsStudents(program.id, !v).then(
        (_res: any) => {
          // this.loading = false;
          this.students = _res.customers;
          this.toastr.success(this.PAGE.lista_programas_aluno, '', this.helpers.toastrCfgs);
          this.getPrograms();
        },
        (err: any) => {
          this.toastr.error(this.STRINGS.algo_errado, '', this.helpers.toastrCfgs);
          // this.toastr.error(err, '', this.helpers.toastrCfgs);
          this.loading = false;
        }
      );
    }, 500);
  }

  programHasAllStudents(program: any) {
    // console.log(file?.customer_users_files?.length);
    // console.log(this.students?.length);
    if (program.students?.length === this.students?.length) {
      return true;
    }
    return false;
  }

  studentHasProgram(student: any, pid: number) {
    let checked: boolean = false;
    // verifica se o program tem algum aluno selecionado para ele
    student.programs.forEach((program: any) => {
      if (program.program_id === pid) {
        checked = true;
      }
    });
    return checked;
  }

  toggleProgramStudent(program: any, student: any) {
    this.toggleActiveProgram(student.id, program.id);
  }

  checkSlideValue(slide: any) {
    return slide._checked;
  }

  toggleActiveProgram(cid: number, pid: number) {
    const data = {
      cid: cid,
      pid: pid
    };
    this.student.admCustomerToggleProgram(data).then(
      (_res: any) => {
        // this.loading = false;
        this.students = this.helpers.sortByProperty(_res.customers, 'name');
        this.helpers.delCacheDB('cachedStudents');
        this.helpers.addCacheDB('cachedStudents', _res.customers);
        this.toastr.success(this.PAGE.lista_arquivos_aluno, '', this.helpers.toastrCfgs);
      },
      (err: any) => {
        this.toastr.error(this.STRINGS.algo_errado, '', this.helpers.toastrCfgs);
        // this.toastr.error(err, '', this.helpers.toastrCfgs);
        this.loading = false;
      }
    );
  }

  goToTutorial() {
    window.open('https://www.stcoach.app/tutoriais-programas', '_system', 'location=yes');
  }  

  goToStudents() {
    this.router.navigate(['professor/alunos/0'], { skipLocationChange: true, queryParams: { tab: 1 } });
  }

  toogleDetails() {
    this.detailsClass = (this.detailsClass === '') ? 'minimize' : '';
  }

  searchProgramChange() {
    this.totalPages = Math.ceil(this.totalItens2.count/this.maxItens2);
  }

  removePagination(page: number = 0) {
    const pageTarget = this.page2 - page;
    this.changePagination(pageTarget);
  }

  addPagination(page: number = 0) {
    const pageTarget = this.page2 + page;
    this.changePagination(pageTarget);
  }

  changePagination(page: number = 0) {
    this.loading = true;
    // console.log(page);
    // console.log(this.page2);
    // console.log(this.totalPages);

    this.page2 = page;
    if (page <= 0) {
      // console.log('page <= 0');
      this.page2 = 0;
    }
    if (page >= this.totalPages) {
      // console.log('page >= this.totalPages');
      this.page2 = this.totalPages - 1;
    }

    setTimeout(() => {
      this.loading = false;
    }, 250);
  }

  toggleClass() {
    if (this.ismobile || this.istablet) {
      // this.mini = !this.mini;
    }
  }
}