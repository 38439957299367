import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from '../../ui/dialog/dialog';
import { Observable } from 'rxjs'

import { CacheProvider } from '../../providers/cache/cache';
import { ConfigProvider } from '../../providers/config/config';
import { FileProvider } from '../../providers/file/file';
import { HelpersProvider } from '../../providers/helpers/helpers';

import { Download } from '../../providers/file/download';
import { DownloadService } from '../../providers/file/download.service';

import { environment } from '../../environments/environment';


@Component({
  selector: 'app-config',
  templateUrl: './config.component.html',
  styleUrls: ['./config.component.scss']
})
export class ConfigComponent implements OnInit {
  loading: boolean = false;
  showSession: string = '';
  showFiles: boolean = false;
  files: any = [];
  downloadBar = [];
  download$: Observable<Download>;
  API_URL = environment.API_ENV_URLS;
  block_new_users: any = '0';
  block_new_customers: any = '0';

  constructor(
    public cacheProvider: CacheProvider,
    private toastr: ToastrService,
    private dialog: MatDialog,
    private config: ConfigProvider,
    private file: FileProvider,
    private downloads: DownloadService,
    private helpers: HelpersProvider,
  ) {
    //
  }

  ngOnInit() {
    //

    this.config.get('block_new_users').then(
      (res: any) => {
        this.block_new_users = res.value;
      },
      (err: any) => {
        console.log(err);
      });

    this.config.get('block_new_customers').then(
      (res: any) => {
        this.block_new_customers = res.value;
      },
      (err: any) => {
        console.log(err);
      });
  }


  getPremiumXLS() {
    window.open(environment.API_ENV_URLS + '/data/adm/premiumByXLS');
  }

  getTeacherPremiumXLS() {
    window.open(environment.API_ENV_URLS + '/data/adm/premiumTeacherByXLS');
  }

  getCustomersByMonthXLS() {
    window.open(environment.API_ENV_URLS + '/data/adm/customersByMonthXLS');
  }

  getUsersByMonthXLS() {
    window.open(environment.API_ENV_URLS + '/data/adm/teachersByMonthXLS');
  }

  getCancelXLS() {
    window.open(environment.API_ENV_URLS + '/data/adm/cancelByXLS');
  }

  delProgramsCache() {
    const msg = 'Deseja apagar o cache de servidor dos Programas?';
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '360px',
      data: {title: 'Apagar', message: msg, confirmation: 'Sim'}
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result === 'Sim') {
        this.cacheProvider.deleteProgramsCache().then(
          (res: any) => {
            console.log(res);
            this.toastr.success('O cache dos Programas foi apagado com sucesso!', '', this.helpers.toastrCfgs);
          },
          (err: any) => {
            console.log(err);
            this.toastr.error(err.error.err, '', this.helpers.toastrCfgs);
          });
      }
    });
  }

  delLibraryCache() {
    const msg = 'Deseja apagar o cache de servidor da Biblioteca?';
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '360px',
      data: {title: 'Apagar', message: msg, confirmation: 'Sim'}
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result === 'Sim') {
        this.cacheProvider.deleteLibraryCache().then(
          (res: any) => {
            console.log(res);
            this.toastr.success('O cache da Biblioteca foi apagado com sucesso!', '', this.helpers.toastrCfgs);
          },
          (err: any) => {
            console.log(err);
            this.toastr.error(err.error.err, '', this.helpers.toastrCfgs);
          });
      }
    });
  }

  delFoodsCache() {
    const msg = 'Deseja apagar o cache de servidor dos Alimentos?';
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '360px',
      data: {title: 'Apagar', message: msg, confirmation: 'Sim'}
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result === 'Sim') {
        this.cacheProvider.deleteFoodsCache().then(
          (res: any) => {
            console.log(res);
            this.toastr.success('O cache dos Alimentos foi apagado com sucesso!', '', this.helpers.toastrCfgs);
          },
          (err: any) => {
            console.log(err);
            this.toastr.error(err.error.err, '', this.helpers.toastrCfgs);
          });
      }
    });
  }

  showCategories() {
    this.showSession = 'categorias';
  }

  showGoals() {
    this.showSession = 'objetivos';
  }

  showMuscles() {
    this.showSession = 'musculos';
  }

  showBkpDB() {
    this.showSession = 'backup';
    this.loading = true;
    this.file.indexDBFiles().then(
      (res: any) => {
        console.log(res);
        for (let a = 0; a < res.files.length; a++) {
          this.downloadBar[a] = this.download$;
        }
        this.files = res.files.reverse();
        this.loading = false;
      },
      (err: any) => {
        console.log(err);
        this.toastr.error('Algo deu errado', '', this.helpers.toastrCfgs);
        this.loading = false;
      }
    );
  }
  closeBkpDB() {
    this.showSession = '';
  }

  fileName(file: string) {
    return file.replace('backup/', '');
  }

  download(name: string, i: number) {
    const url = this.API_URL + '/v2/adm/dbfiles/download/' + name.replace('backup/', '');
    this.downloadBar[i] = this.downloads.download(url, name)
  }

  workoutVideos() {
    this.showSession = 'workout_videos';
  }

  migrateWorkoutVideos() {
    console.log('teste');
  }

  newUsersChange() {
    let data = (this.block_new_users) ? '1' : '0';

    if (data === '1') {
      this.toastr.warning('Fechando cadastro de Professor...', '', this.helpers.toastrCfgs);
    } else {
      this.toastr.warning('Abrindo cadastro de Professor...', '', this.helpers.toastrCfgs);
    }

    // console.log(this.block_new_users);
    // console.log(data); return false;
    this.config.set('block_new_users', data).then(
      (res: any) => {
        console.log(res);
        this.toastr.success('Configuração atualizada', '', this.helpers.toastrCfgs);
        // this.block_new_customers = res.value;
      },
      (err: any) => {
        console.log(err);
      });
  }

  newCustomersChange() {
    let data = (this.block_new_customers) ? '1' : '0';

    if (data === '1') {
      this.toastr.warning('Fechando cadastro de Alunos/SuperTreinos...', '', this.helpers.toastrCfgs);
    } else {
      this.toastr.warning('Liberando cadastro de Alunos/SuperTreinos...', '', this.helpers.toastrCfgs);
    }

    // console.log(this.block_new_users);
    // console.log(data); return false;
    this.config.set('block_new_customers', data).then(
      (res: any) => {
        console.log(res);
        this.toastr.success('Configuração atualizada', '', this.helpers.toastrCfgs);
        // this.block_new_customers = res.value;
      },
      (err: any) => {
        console.log(err);
      });
  }

}
