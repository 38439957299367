import { Injectable } from '@angular/core';
import { ApiProvider } from '../api/api';

@Injectable()
export class ConfigProvider {

  constructor(
    public api: ApiProvider
  ) {
    //
  }

  get(config: string = '') {
    const url = 'master/v2/config';
    return this.api.get(url, { params: { s: config } });
  }

  set(config: string = '', value: any = null) {
    let data = {config: config, value: value}
    return this.api.post(`master/v2/config/`, data);
  }

  // program(id: any) {
  //   return this.api.get(`v2/programs/${id}`);
  // }

  // patchProgram(program: any) {
  //   program._method = 'PATCH';
  //   return this.api.post(`v2/programs/${program.id}`, program);
  // }

  // postProgram(program: any) {
  //   return this.api.post(`v2/programs/`, program);
  // }

  // deleteProgram(id: any) {
  //   return this.api.delete(`v2/programs/${id}`);
  // }

}
